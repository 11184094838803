/* =============================================================================
   FULLPAGE SLIDER (components/_fullpage-slider.scss)
   ========================================================================== */

.c-fullpage-slider__section {
	overflow: hidden;
}

.c-fullpage-slider__section__title {
	font-size: 3rem;

	@include media-breakpoint-up( md ) {
		font-size: 4.25rem;
	}

	@include media-breakpoint-up( lg ) {
		font-size: 5.5rem;
	}

	@include media-breakpoint-up( xl ) {
		font-size: 6.75rem;
	}
}
