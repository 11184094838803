/* =============================================================================
   BUTTONS (settings/_buttons.scss)
   ========================================================================== */

.c-button {
	display: block;
	background-color: color( extra, white );
	border: 3px solid color( extra, white );
	font-size: 1.25rem;
	font-weight: $font-weight-black;
	text-align: center;
	transition: all .2s;

	&:active,
	&:focus,
	&:hover {
		background-color: transparent;
		color: color( extra, white );
	}

	@include media-breakpoint-up( md ) {
		font-size: 1.5rem;
	}

	@include media-breakpoint-up( lg ) {
		font-size: 1.75rem;
	}

	@include media-breakpoint-up( xl ) {
		font-size: 2rem;
	}
}

// Color variations
.c-button--primary {
	color: color( primary );
}

.c-button--secondary {
	color: color( primary, orange );
}

