/* =============================================================================
   TALK CARD (components/_talk-card.scss)
   ========================================================================== */

.c-talk-card__pic {
	display: block;
	position: relative;
}

.c-talk-card__play-btn {
	display: block;
	width: 4rem;
	height: 4rem;
	background-color: color( primary, orange );
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate( -50%, -50% );
	font-size: 2rem;
	text-align: center;
	text-indent: .3125rem;
	color: color( extra, white );
	line-height: 3.875rem;
	transition: color .2s;

	.c-talk-card__pic:hover &,
	.c-talk-card__pic:focus & {
		color: color( primary );
	}
}

.c-talk-card__content {
	display: none;
	padding: 1.25rem 1.875rem;
	border: 4px solid color( secondary, blue-tropical );
	border-top: none;
}

.c-talk-card__headline {
	margin-bottom: 0;
	font-size: 1.125rem;
	line-height: 1.66;
	color: color( primary );

	a {
		color: currentColor;
		border-bottom: 2px solid transparent;
		transition: border-color .2s;

		&:hover {
			border-color: currentColor;
		}
	}
}
