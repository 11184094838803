/* =============================================================================
   COLORS (settings/_colors.scss)
   ========================================================================== */

/*
 * Colors that are most represented in the design, every project must contain at
 * least one primary color.
 */

$color-primary: (
	primary: (
		default: #45027a, // indigo
		orange: #fd9d28,
	)
) !default;

/*
 * Colors that complement primary palette, every project must contain at least
 * one secondary color.
 */

$color-secondary: (
	secondary: (
		default: #dde2fb, // lavander
		almond: #fee6cd,
		blue-cornflower: #a5b1f3,
		blue-perano: #9aa1e5,
		blue-tropical: #bdc3f0,
	)
) !default;

/**
 * Colors used on notifications or interactive elements/forms with dynamic
 * states.
 */

$color-extra: (
	extra: (
		black: #000,
		white: #fff,
		links: #0097e6,
		success: #49c781,
		info: #c0e1f0,
		warning: #e6e448,
		error: #f25454,
	)
) !default;

/**
 * Merge all color maps into one.
 */

$colors: map-merge(
	map-merge( $color-primary, $color-secondary ), $color-extra
) !default;

/**
 * Colors used for base things such as base text color and links color.
 *
 * Don't use `color()` mixin in following variables, they're not available in
 * this (settings) layer.
 */

$color-base: color( extra, black ) !default;
$color-links: color( extra, links ) !default;

/**
 * Set a specific jump point for requesting color jumps.
 */

$color-shade-percentage: 10% !default;
