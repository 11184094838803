/* =============================================================================
   TESTIMONIAL (components/_testimonial.scss)
   ========================================================================== */

.c-testimonial {
	margin-bottom: 3.75rem;
	padding: 1.25rem;
	background-color: color( secondary );
	border: 4px solid color( secondary, blue-tropical );
	font-size: 1.125rem;
	font-weight: $font-weight-bold;
	color: color( primary );
	position: relative;

	p {
		margin-bottom: 0;
	}

	strong {
		color: color( secondary, blue-tropical );
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
	}

	&--notch-bottom-left,
	&--notch-bottom-right,
	&--notch-top-left,
	&--notch-top-right {
		&::before {
			border-left: 25px solid transparent;
			border-right: 25px solid transparent;
		}

		&::after {
			border-left: 21px solid transparent;
			border-right: 21px solid transparent;
		}
	}

	&--notch-bottom-left,
	&--notch-bottom-right {
		&::before {
			border-top: 45px solid color( secondary, blue-tropical );
			top: 100%;
		}

		&::after {
			border-top: 37px solid color( secondary );
			top: 100%;
		}
	}

	&--notch-top-left,
	&--notch-top-right {
		&::before {
			border-bottom: 45px solid color( secondary, blue-tropical );
			bottom: 100%;
		}

		&::after {
			border-bottom: 37px solid color( secondary );
			bottom: 100%;
		}
	}

	&--notch-bottom-left,
	&--notch-top-left {
		&::before {
			left: 1.25rem;
		}

		&::after {
			left: 1.5rem;
		}
	}

	&--notch-bottom-right,
	&--notch-top-right {
		&::before {
			right: 1.25rem;
		}

		&::after {
			right: 1.5rem;
		}
	}

	&--notch-right-center,
	&--notch-left-center {
		&::before {
			border-top: 25px solid transparent;
			border-bottom: 25px solid transparent;
			top: 50%;
			transform: translateY( -50% );
		}

		&::after {
			border-top: 21px solid transparent;
			border-bottom: 21px solid transparent;
			top: 50%;
			transform: translateY( -50% );
		}
	}

	&--notch-right-center {
		&::before {
			border-left: 45px solid color( secondary, blue-tropical );
			left: 100%;
		}

		&::after {
			border-left: 37px solid color( secondary );
			left: 100%;
		}
	}

	&--notch-left-center {
		&::before {
			border-right: 45px solid color( secondary, blue-tropical );
			right: 100%;
		}

		&::after {
			border-right: 37px solid color( secondary );
			right: 100%;
		}
	}

	@include media-breakpoint-down( sm ) {
		width: 100%;
	}

	@include media-breakpoint-up( md ) {
		max-width: 35rem;
	}
}
