/* =============================================================================
   VIDE BG (components/_video-bg.scss)
   ========================================================================== */

.c-video-bg {
	position: absolute;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;

	&::before {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		top: 0;
		left: 0;
		background-color: color( extra, black );
		opacity: .3;
	}
}

.c-video-bg__media {
	width: 100%;
	height: 100%;
	background-color: color( extra, black );  // in case the video doesn't fit the whole page
	background-position: center center;
	background-size: contain;
	object-fit: cover;  // cover video background
}
