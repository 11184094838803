/* =============================================================================
   INTRO (components/_intro.scss)
   ========================================================================== */

.c-intro {
	padding-top: 5.625rem;
	padding-bottom: 5.625rem;
	color: color( primary, orange );
}

.c-intro__logo {
	display: block;
	width: 14.375rem;
	height: 3.75rem;
	margin-bottom: 2.5rem;
	color: color( extra, white );

	svg {
		height: 100%;
		width: 100%;
	}

	@include media-breakpoint-up( xl ) {
		margin-left: -5rem;
	}
}

.c-intro__headline {
	margin-bottom: 1.875rem;
	font-size: 2.25rem;
	color: currentColor;

	&:last-child {
		margin-bottom: 0;
	}

	@include media-breakpoint-up( md ) {
		margin-bottom: 2.75rem;
		font-size: 2.5rem;
	}

	@include media-breakpoint-up( lg ) {
		font-size: 3.375rem;
	}

	@include media-breakpoint-up( xl ) {
		font-size: 3.875rem;
	}
}

.c-intro__text {
	font-size: 1.25rem;
	font-weight: $font-weight-bold;
	color: currentColor;

	p {
		&:last-child {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-up( md ) {
		padding-left: 5.625rem;
		font-size: 1.5rem;
	}

	@include media-breakpoint-up( lg ) {
		font-size: 1.75rem;
	}

	@include media-breakpoint-up( xl ) {
		max-width: 80%;
		font-size: 2rem;
	}
}

.c-intro__dev {
	font-size: .625rem;
	color: color( extra, white );
	position: absolute;
	bottom: 3.5rem;
	left: 20px;

	a {
		display: inline-block;
		position: relative;
		top: -.125em;
	}

	svg {
		height: .5rem;
		width: 2.5rem;
	}

	@include media-breakpoint-up( md ) {
		left: auto;
		right: -2rem;
		bottom: 12.5rem;
		transform: rotate( -90deg );
	}
}
