/* =============================================================================
   EVENT (components/_event.scss)
   ========================================================================== */

.c-event {
	padding-top: 5.625rem;
	padding-bottom: 5.625rem;
}

.c-event__lead {
	margin-bottom: 1.875rem;
	line-height: 1;
	font-weight: $font-weight-black;
	text-align: center;
	text-transform: lowercase;
	color: color( secondary, blue-perano );
}

.c-event__headline {
	margin-bottom: 1.875rem;
	font-size: 1.5rem;
	color: color( primary );

	@include media-breakpoint-up( md ) {
		font-size: 2rem;
	}

	@include media-breakpoint-up( lg ) {
		font-size: 2.25rem;
	}

	@include media-breakpoint-up( xl ) {
		font-size: 2.875rem;
	}
}

.c-event__info {
	@extend .o-list-bare;
	padding-left: 2.25rem;
	margin-bottom: 3.75rem;
	font-weight: $font-weight-bold;
	font-size: 1.25rem;
	color: color( primary );

	li {
		position: relative;

		svg {
			position: absolute;
			right: 100%;
			top: .25em;
			margin-right: 1rem;
		}
	}

	@include media-breakpoint-up( md ) {
		font-size: 1.5rem;
	}

	@include media-breakpoint-up( lg ) {
		padding-left: 0;
		font-size: 1.75rem;
	}

	@include media-breakpoint-up( xl ) {
		font-size: 2rem;
	}
}

.c-event__button {
	max-width: 25rem;
}

.c-event__bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
}

.c-event__bg__part {
	position: absolute;
	height: 100%;
	width: 100%;

	&[ style*='background-image' ] {
		background-size: cover;
		background-position: center center;
	}

	@include media-breakpoint-up( md ) {
		width: 50%;

		&:first-child {
			left: 0;
		}

		&:last-child {
			right: 0;
		}
	}
}
