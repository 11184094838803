/* =============================================================================
   CTA (settings/_cta.scss)
   ========================================================================== */

.c-cta {
	@include media-breakpoint-down( sm ) {
		height: 100%;
	}

	@include media-breakpoint-up( md ) {
		display: flex;
	}
}

.c-cta__item {
	@include media-breakpoint-down( sm ) {
		min-height: 50%;
		display: flex;
		align-items: center;
		position: relative;
	}

	@include media-breakpoint-up( md ) {
		width: 50%;
	}
}

.c-cta__card {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 5.625rem 20px; // 20px = grid gutter / 2

	@include media-breakpoint-up( md ) {
		max-width: 41.5rem;

		.c-cta__item:first-child & {
			padding-right: 3.125rem;
			float: right;
		}

		.c-cta__item:last-child & {
			padding-left: 3.125rem;
		}
	}
}

.c-cta__card__headline {
	margin-bottom: 1.875rem;
	font-size: 3.5rem;
	line-height: 1.16;
	color: currentColor;

	@include media-breakpoint-up( md ) {
		margin-bottom: 3.125rem;
		font-size: 4.25rem;
	}

	@include media-breakpoint-up( lg ) {
		font-size: 5rem;
	}

	@include media-breakpoint-up( xl ) {
		font-size: 5.75rem;
	}
}

.c-cta__card__punchline {
	margin-bottom: 2.8125rem;
	font-size: 1.25rem;
	font-weight: $font-weight-bold;
	color: currentColor;

	@include media-breakpoint-up( md ) {
		margin-bottom: 4.0625rem;
		font-size: 1.5rem;
	}

	@include media-breakpoint-up( lg ) {
		font-size: 1.75rem;
	}

	@include media-breakpoint-up( xl ) {
		font-size: 2rem;
	}
}

.c-cta__card__button {
	max-width: 22.375rem;
	margin-top: auto;
}

.c-cta__card__bg {
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: -1;

	@include media-breakpoint-up( md ) {
		width: 50%;

		.c-cta__item:first-child & {
			left: 0;
		}

		.c-cta__item:last-child & {
			left: 50%;
		}
	}
}
