/*!
 * fullPage 3.0.3
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */

 /* stylelint-disable */

 html.fp-enabled,
 .fp-enabled body {
	 margin: 0;
	 padding: 0;
	 overflow:hidden;

	 /*Avoid flicker on slides transitions for mobile phones #336 */
	 -webkit-tap-highlight-color: rgba(0,0,0,0);
 }

 .fp-section {
	 position: relative;
	 -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
	 -moz-box-sizing: border-box; /* <=28 */
	 box-sizing: border-box;
 }

 .fp-slide {
	 float: left;
 }

 .fp-slide, .fp-slidesContainer {
	 height: 100%;
	 display: block;
 }

 .fp-slides {
	 z-index:1;
	 height: 100%;
	 overflow: hidden;
	 position: relative;
	 transition: all 0.3s ease-out;
 }

 .fp-section.fp-table, .fp-slide.fp-table {
	 display: table;
	 table-layout:fixed;
	 width: 100%;
 }

 .fp-tableCell {
	 display: table-cell;
	 vertical-align: middle;
	 width: 100%;
	 height: 100%;
 }

 .fp-slidesContainer {
	 float: left;
	 position: relative;
 }

 .fp-controlArrow {
	 -webkit-user-select: none; /* webkit (safari, chrome) browsers */
	 -moz-user-select: none; /* mozilla browsers */
	 -khtml-user-select: none; /* webkit (konqueror) browsers */
	 -ms-user-select: none; /* IE10+ */
	 position: absolute;
	 z-index: 4;
	 top: 50%;
	 cursor: pointer;
	 width: 0;
	 height: 0;
	 border-style: solid;
	 margin-top: -38px;
	 transform: translate3d(0,0,0);
 }

 .fp-controlArrow.fp-prev {
	 left: 15px;
	 width: 0;
	 border-width: 38.5px 34px 38.5px 0;
	 border-color: transparent #fff transparent transparent;
 }

 .fp-controlArrow.fp-next {
	 right: 15px;
	 border-width: 38.5px 0 38.5px 34px;
	 border-color: transparent transparent transparent #fff;
 }

 .fp-scrollable {
	 overflow: hidden;
	 position: relative;
 }

 .fp-scroller {
	 overflow: hidden;
 }

 .iScrollIndicator {
	 border: 0 !important;
 }

 .fp-notransition {
	 transition: none !important;
 }

 #fp-nav {
	 position: fixed;
	 z-index: 100;
	 margin-top: 0 !important;
	 top: 50%;
	 opacity: 1;
	 visibility: visible;
	 transform: translateY(-50%);
	 transition: all .25s ease-in-out;

	 .last-fp-slide-is-active & {
		 opacity: 0;
		 visibility: hidden;
	 }
 }

 #fp-nav.fp-right {
	 right: 10px;
 }

 #fp-nav.fp-left {
	 left: 10px;
 }

 .fp-slidesNav {
	 position: absolute;
	 z-index: 4;
	 opacity: 1;
	 transform: translate3d(0,0,0);
	 left: 0 !important;
	 right: 0;
	 margin: 0 auto !important;
 }

 .fp-slidesNav.fp-bottom {
	 bottom: 17px;
 }

 .fp-slidesNav.fp-top {
	 top: 17px;
 }

 #fp-nav ul,
 .fp-slidesNav ul {
	 margin: 0;
	 padding: 0;
 }

 #fp-nav ul li,
 .fp-slidesNav ul li {
	 display: block;
	 margin: 8px;
	 position:relative;

	 &:last-child {
		 display: none; // hides last nav item (slide containing page blocks)
	 }
 }

 .fp-slidesNav ul li {
	 display: inline-block;
 }

 #fp-nav ul li a,
 .fp-slidesNav ul li a {
	 display: block;
	 width: 10px;
	 height: 10px;
	 position: relative;
	 z-index: 1;
	 cursor: pointer;
	 text-decoration: none;
 }

 #fp-nav ul li a.active span,
 .fp-slidesNav ul li a.active span,
 #fp-nav ul li:hover a.active span,
 .fp-slidesNav ul li:hover a.active span{
	 height: 10px;
	 width: 10px;
	 border-radius: 100%;
	 border-color: color(extra, white);
	 background-color: transparent;

	 .fp-viewing-0 & {
		 border-color: color(primary, onyx);
		 background-color: transparent;
	 }
 }

 #fp-nav ul li a span,
 .fp-slidesNav ul li a span {
	 border-radius: 50%;
	 position: absolute;
	 z-index: 1;
	 height: 10px;
	 width: 10px;
	 border: 2px solid transparent;
	 background-color: rgba(255, 255, 255, .3);
	 transition: all 0.25s ease-in-out;

	 .fp-viewing-0 & {
		 background-color: rgba(54, 55, 71, .3);
	 }
 }

 #fp-nav ul li:hover a span,
 .fp-slidesNav ul li:hover a span{
	 width: 10px;
	 height: 10px;
	 opacity: 1;
	 background-color: color(extra, white);

	 .fp-viewing-0 & {
		 background-color: color(primary, onyx);
	 }
 }

 #fp-nav ul li .fp-tooltip {
	 position: absolute;
	 top: -2px;
	 color: #fff;
	 font-size: 14px;
	 font-family: arial, helvetica, sans-serif;
	 white-space: nowrap;
	 max-width: 220px;
	 overflow: hidden;
	 display: block;
	 opacity: 0;
	 width: 0;
	 cursor: pointer;
 }

 #fp-nav ul li:hover .fp-tooltip,
 #fp-nav.fp-show-active a.active + .fp-tooltip {
	 transition: opacity 0.2s ease-in;
	 width: auto;
	 opacity: 1;
 }

 #fp-nav ul li .fp-tooltip.fp-right {
	 right: 20px;
 }

 #fp-nav ul li .fp-tooltip.fp-left {
	 left: 20px;
 }

 .fp-auto-height.fp-section,
 .fp-auto-height .fp-slide,
 .fp-auto-height .fp-tableCell{
	 height: auto !important;
 }

 .fp-responsive .fp-auto-height-responsive.fp-section,
 .fp-responsive .fp-auto-height-responsive .fp-slide,
 .fp-responsive .fp-auto-height-responsive .fp-tableCell {
	 height: auto !important;
 }

  /*Only display content to screen readers*/
 .fp-sr-only{
	 position: absolute;
	 width: 1px;
	 height: 1px;
	 padding: 0;
	 overflow: hidden;
	 clip: rect(0, 0, 0, 0);
	 white-space: nowrap;
	 border: 0;
 }
