/* =============================================================================
   INFO SLIDER (components/_info-slider.scss)
   ========================================================================== */

.c-info-slider {
	padding-top: .625rem;
	padding-bottom: .625rem;
	background-color: color( primary );
	display: none;

	&.slick-initialized {
		display: block;
	}
}

.c-info-slider__item {
	padding-left: $grid-gutter / 2;
	padding-right: $grid-gutter / 2;
	font-size: .875rem;
	font-weight: $font-weight-bold;
	color: color( extra, white );
	text-transform: uppercase;
	opacity: .4;
	transition: opacity .2s;

	&.slick-current {
		opacity: 1;
	}

	@include media-breakpoint-up( sm ) {
		text-align: center;
		padding-left: 0;
		padding-right: 0;
	}
}
