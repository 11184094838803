/* =============================================================================
   TYPOGRAPHY (settings/_typography.scss)
   ========================================================================== */

/* Base
   ========================================================================== */

$font-size-base: 16px !default;
$line-height-base: 1.5 !default;
$font-family-base: 'Montserrat', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;



/* Headings
   ========================================================================== */

$font-size-h1: 6.75rem !default; // 108px
$font-size-h2: 5.75rem !default; // 92px
$font-size-h3: 3.875rem !default; // 62px
$font-size-h4: 2.875rem !default; // 46px
$font-size-h5: 2rem !default; // 32px
$font-size-h6: 1.25rem !default; // 18px


/* Font Weights
   ========================================================================== */

$font-weight-regular: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

