/* =============================================================================
   TYPOGRAPHY (utilities/_typography.scss)
   ========================================================================== */

/* stylelint-disable declaration-no-important */

/* Font Weights
   ========================================================================== */

/**
 * Utilities for controlling the font weight of an element.
 */

.u-font-regular {
	font-weight: $font-weight-regular !important;
}

.u-font-bold {
	font-weight: $font-weight-bold !important;
}

.u-font-black {
	font-weight: $font-weight-black !important;
}



/* Font Styles & Decorations
   ========================================================================== */

/**
 * Utilities for controlling the style of text.
 */

.u-font-uppercase {
	text-transform: uppercase !important;
}
