/* =============================================================================
   SITE HEADER (components/_site-header.scss)
   ========================================================================== */

.c-site-header {
	width: 100%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	color: color( extra, white );
	position: fixed;
	top: 0;
	z-index: 1000; // above offcanvas
	transition: background-color .2s .5s;

	.fp-viewing-about &,
	.fp-viewing-talks &,
	.fp-viewing-testimonials & {
		color: color( primary );
	}

	.fp-viewing-talks & {
		background-color: rgba( color( secondary ), .75 );
	}

	.c-offcanvas.is-active + & {
		color: color( extra, white );
	}

	@include media-breakpoint-up( md ) {
		padding-top: 1.875rem;
		padding-bottom: 1.875rem;
	}
}

.c-site-header__inner {
	position: relative;
	display: flex;
	align-items: center;
}

.c-site-header__logo {
	display: block;
	height: 2.5rem;
	width: 2.5rem;
	margin-right: 2rem;
	color: currentColor;
	visibility: visible;
	opacity: 1;

	svg {
		height: 100%;
		width: 100%;
	}

	.fp-viewing-home & {
		opacity: 0;
		visibility: hidden;
		display: none;
	}

	body:not( .fp-viewing-home ) & {
		transition: opacity 1s,
					visibility 1s,
					color 1s;
	}

	@include media-breakpoint-up( xl ) {
		body:not( .fp-viewing-home ) & {
			margin-right: 4.375rem;
			position: absolute;
			right: 100%;
			top: 50%;
			transform: translateY( -50% );
		}
	}
}

.c-site-header__nav {
	flex-grow: 2;

	@include media-breakpoint-down( md ) {
		display: none;
	}
}

.c-site-header__nav__list {
	@extend .o-list-bare;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.c-site-header__nav__item {
	&--logo {
		height: 2.5rem;
		width: 2.5rem;
		position: absolute;
		right: 100%;
		margin-right: 5rem;
	}
}

.c-site-header__nav__link {
	display: block;
	font-size: 1.125rem;
	font-weight: $font-weight-bold;
	color: currentColor;
	transition: color 1s;
	position: relative;

	&::after {
		content: '';
		width: 0;
		height: 2px;
		background-color: currentColor;
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		transition: width .2s;
	}

	&:hover,
	.c-site-header__nav__item.active & {
		&::after {
			width: 100%;
		}
	}
}
