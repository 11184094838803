/* =============================================================================
   SITE FOOTER (components/_site-footer.scss)
   ========================================================================== */

.c-site-footer {
	width: 100%;
	position: fixed;
	bottom: 0;
}

.c-site-footer__info {
	padding: .625rem 20px;
	display: none;
	align-items: center;
	justify-content: space-between;
	background-color: color( primary, orange );
	font-size: .625rem;
	color: color( extra, white );

	.fp-viewing-testimonials & {
		display: flex;
	}
}

.c-site-footer__copy,
.c-site-footer__dev {
	margin-bottom: 0;
}

.c-site-footer__dev {
	a {
		display: inline-block;
		position: relative;
		top: -.125em;
	}

	svg {
		height: .5rem;
		width: 2.5rem;
	}
}

