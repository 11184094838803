/* =============================================================================
   OFFCANVAS (components/_offcanvas.scss)
   ========================================================================== */

.c-offcanvas {
	padding: 5rem 1.875rem 1.875rem 1.875rem;
	text-align: center;
}

.c-offcanvas__nav__list {
	@extend .o-list-bare;
	margin-bottom: 0;
}

.c-offcanvas__nav__item {
	margin-bottom: 1rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-offcanvas__nav__link {
	display: inline-block;
	font-size: 1.125rem;
	font-weight: $font-weight-bold;
	color: color( extra, white );
	transition: color 1s;
	position: relative;

	&::after {
		content: '';
		width: 0;
		height: 2px;
		background-color: currentColor;
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		transition: width .2s;
	}

	&:hover,
	.c-offcanvas__nav__item.active & {
		&::after {
			width: 100%;
		}
	}
}
